@use '@fortawesome/fontawesome-pro/scss/functions';

@mixin animation($type, $value){
    -webkit-animation-#{$type}: $value;
       -moz-animation-#{$type}: $value;
        -ms-animation-#{$type}: $value;
         -o-animation-#{$type}: $value;
            animation-#{$type}: $value;
}

@mixin font-face($name, $family, $weight: normal, $style: normal){
    @font-face {
      font-family: $family;
      src: url('../fonts/#{$name}/#{$name}.eot?#iefix') format('embedded-opentype'),  url('../fonts/#{$name}/#{$name}.woff') format('woff'), url('../fonts/#{$name}/#{$name}.ttf')  format('truetype'), url('../fonts/#{$name}/#{$name}.svg#Roboto-Light') format('svg');
      font-weight: $weight;
      font-style: $style;
    }
}

@mixin icon($icon) {
    @extend %fa-icon;

    font: var(--fa-font-solid);
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: functions.fa-content($icon);
}

@mixin box-shadow($top, $left, $blur, $value, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $value $color;
        -moz-box-shadow:inset $top $left $blur $value $color;
        box-shadow:inset $top $left $blur $value $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $value $color;
        -moz-box-shadow: $top $left $blur $value $color;
        box-shadow: $top $left $blur $value $color;
    }
}

@mixin media-breakpoint-down($name: sm) {
    @if ($name == xs) {
        @media screen and (max-width: 480px){
            @content;
        }
    }
    @if ($name == sm) {
        @media screen and (max-width: 767px){
            @content;
        }
    }
    @if ($name == md) {
        @media screen and (max-width: 991px){
            @content;
        }
    }
    @if ($name == lg) {
        @media screen and (max-width: 1130px){
            @content;
        }
    }
}
@mixin media-breakpoint-up($name: sm) {
    @if ($name == xs) {
        @media screen and (min-width: 481px){
            @content;
        }
    }
    @if ($name == sm) {
        @media screen and (min-width: 768px){
            @content;
        }
    }
    @if ($name == md) {
        @media screen and (min-width: 992px){
            @content;
        }
    }
    @if ($name == lg) {
        @media screen and (min-width: 1131px){
            @content;
        }
    }
}

@mixin set-tablebuilder-row-wize($nth-element, $width) {
    .tb-head {
        th {
            &:nth-of-type(#{$nth-element}) {
                width: $width !important;
                > .tb-filter_container {
                    width: $width - 20px !important;
                    min-width: $width - 20px !important;
                    .select2-container {
                        min-width: 0 !important;
                        .select2-chosen {
                            padding-right: 20px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
