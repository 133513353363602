@use '../abstracts/variables';
@import './vendor/pace-theme-flash.css'; // a webpack csak így találja meg

.pace {
    .pace-activity {
        top: 72px;
        right: 15px;
        left: auto;
        border-top-color: variables.$color6-darker;
        border-left-color: variables.$color6-darker;
    }

    .pace-progress {
        background: variables.$color6-darker;
        right: auto;
        left: 0;
    }

    .pace-progress-inner {
        box-shadow: 0 0 10px variables.$color6-darker, 0 0 5px variables.$color6-darker;
    }
}

.no-top .pace .pace-activity {
    top: 10px;
}
