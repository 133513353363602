@use 'sass:color';
@import '~@fortawesome/fontawesome-pro/scss/functions';
@import '~@fortawesome/fontawesome-pro/scss/variables';

$color-body-bg: #1b1e24;
$color-font-bg: #6f7b8a;

$color1: #3c3c3b;
$color2: #2f2f2e;
$color3: #222221;
$color4: #ababab;
$color5: #f35958;//red
$color6: #30cbc9;//turkiz
$color6-darker: #0aa699;//turkiz
$color7: #e5e5e5;//grey content bg
$color8: #f26b63;//red fooldali doboz
$color9: #f0a04a;//orange fooldali doboz
$color10: #f2f2f2;// tablebuilder egyik sora (a zebrából a sötétebb)
$color11: #2fcc71;// button bg succes
$color12: #919191;// logo szürke
$color13: $color2 ;// menu ul bg
$color14: #b370ae ;// purple

//falcon-colors

$white: #fff;
$black: #000;
$color_tuna_approx: #333843; // placeholder 5 , border-right1
$color_mirage_approx: $color-body-bg;
$color_raven_approx: $color-font-bg;
$color_celeste_approx: #ccc;
$black_20: rgba($black, 0.2);
$color_shark_approx: #22262e;
$color_log_cabin_approx: #1f1f1f;
$color_catskill_white_approx: #eff4f8;
$gray: #808080;
$color_carnation_approx: $color5;
$color_woodsmoke_approx: #0d0f12;
$color_storm_dust_approx: #656565;
$color_bon_jour_approx: #e1e1e1;
$color_oslo_gray_approx: #8b8f92;
$color_mystic_approx: #e5e9ec;
$color_gunsmoke_approx: #808285;
$color_persian_green_approx: $color6-darker;
$color_manatee_approx: #8b91a0;
$color_mine_shaft_approx: #313131;
$color_niagara_approx: #0b9c8f;
$color_mountain_mist_approx: #919091;
$black_40: rgba(0, 0, 0, 0.4);
$color_thunder_approx: #372b32;
$color_woody_brown_approx: #482f36;
$color_black_haze_approx: #f5f6f8;
$color_cosmos_approx: #fed8db;
$color_mid_gray_approx: #5e646d;
$color_outer_space_approx: #2d3139;
$color_trout_approx: #4c5264;
$black_15: rgba(0, 0, 0, 0.15);
$black_8: rgba(0, 0, 0, 0.08);
$color_jumbo_approx: #7b7d82;
$color_pewter_approx: #929fa5;
$color_alabaster_approx: #f9f9f9;
$color_gallery_approx: #eeeeee;
$color_silver_chalice_approx: #aaaaaa;
$color_charade_approx: #2a2e36;
$color_tundora_approx: #444;
$color_bahama_blue_approx: #0d638f;
$color_cerulean_approx: #0090d9;
$color_star_dust_approx: #9a9a9a;
$color_shuttle_gray_approx: #5c6370;
$color_french_gray_approx: #b6bfc5;
$color_texas_rose_approx: #fbb05e;
$color_rum_approx: #735f87;
$color_romance_approx: #fefefe;
$color_boulder_approx: #73777c;
$color_suva_gray_approx: #888;
$color_fuscous_gray_approx: #515050;
$color_iron_approx: #d1dade;
$color_nevada_approx: #656c78;
$color_geyser_approx: #d7dbe0;
$white_25: rgba(255, 255, 255, 0.25);
$color_valencia_approx: #d44443;
$white_10: rgba(255, 255, 255, 0.1);
$white_80: rgba(255, 255, 255, 0.8);
$seashell: #f1f1f1;
$mercury: $color7;
$color_rolling_stone_approx: #777a80;
$color_loblolly_approx: #c2c6cb;
$color_nile_blue_approx: #1f3853;
$color_abbey_approx: #4d5056;
$color_athens_gray_approx: #eceff3;
$color_santas_gray_approx: #97a3b3;
$color_gold_approx: #ffd200;
$color_alto_approx: #ddd;
$color_alizarin_crimson_approx: #e02222;
$color_tower_gray_approx: #b1bcc5;
$color_pickled_bluewood_approx: #2b3d53;
$color_gray_chateau_approx: #a7abb1;
$color_chicago_approx: #5e5e5e;
$color_mischka_approx: #d0d3d8;
$color_spindle_approx: #b4cef8;
$color_picton_blue_approx: #58acf3;
$color_chardonnay_approx: #fccb7e;
$color_yellow_orange_approx: #ffb848;
$color_fuchsia_pink_approx: #af5cc1;
$color_vivid_violet_approx: #852b99;
$white_35: rgba(255, 255, 255, 0.35);
$color_tropical_blue_approx: #bfd5fa;
$alabaster: #fafafa;
$color_wild_sand_approx: #f5f5f5;
$color_geebung_approx: #ce8f22;
$island_spice: #fffcee;
$color_chamois_approx: #f0dbb4;
$color_smalt_blue_approx: #438a85;
$color_black_squeeze_approx: #f3faf8;
$color_tiara_approx: #bfd1cf;
$color_calypso_approx: #246a8e;
$color_solitude_approx: #ebf6fb;
$color_ziggurat_approx: #bfd4de;
$color_sunglo_approx: #e76e70;
$color_rose_white_approx: #fff6f4;
$color_azalea_approx: #f8cdcd;
$color_iceberg_approx: #d9edf1;
$color_chardon_approx: #fef2f2;
$color_pippin_approx: #fae1e1;
$color_zumthor_approx: #edf7fc;
$color_link_water_approx: #d8ebf4;
$color_gin_approx: #e9ecee;
$black_28: rgba(0, 0, 0, 0.28);
$color_candlelight_approx: #fdd01c;
$color_comet_15_approx: rgba(86, 96, 117, 0.15);
$color_silver_approx: #bcbcbc;
$color_flush_mahogany_approx: #bf3938;
$color_beauty_bush_approx: #f7bebe;
$black_35: rgba(0, 0, 0, 0.35);
$color_azure_approx: #365d98;
$color_wild_watermelon_approx: #f96773;
$color_gunsmoke_65_approx: rgba(131, 131, 131, 0.65);
$black_65: rgba(0, 0, 0, 0.65);
$black_42: rgba(0, 0, 0, 0.42);
$color_teal_approx: #08897e;
$color_persian_green_80_approx: rgba(10, 166, 153, 0.8);
$color_cerulean_80_approx: rgba(0, 144, 217, 0.8);
$color_submarine_approx: #b9c3c8;
$color_boston_blue_approx: #2d8ebf;
$color_lochmara_approx: #0082d4;
$color_elephant_approx: #1b3148;
$color_tan_hide_approx: #faa652;
$color_sunset_orange_approx: #f14d4d;
$color_mojo_approx: #b94141;
$color_cinnabar_approx: #e94847;
$color_bombay_approx: #b4b9be;
$color_mercury_approx: #e6e6e6;
$color_comet_20_approx: rgba(86, 96, 117, 0.2);
$porcelain: #eff2f3;
$color_burnt_umber_approx: #832929;
$color_chathams_blue_approx: #0a5174;
$black_10: rgba(0, 0, 0, 0.1);
$color_tradewind_33_approx: rgba(107, 170, 165, 0.33);
$black_39: rgba(0, 0, 0, 0.39);
$black_11: rgba(0, 0, 0, 0.11);
$color_allports_approx: #0a6ea0;
$color_red_berry_approx: darkred;
$color_cream_approx: #ffffcc;
$color_comet_approx: #576475;
$color_drover_approx: #fef8ae;
$color_pumice_approx: #c5c8cb;
$color_coral_red_approx: #fb3c4a;
$color_baltic_sea_approx: #292b2e;
$color_silver_sand_approx: #bfc0c1;
$color_cape_cod_approx: #414243;
$white_40: rgba(255, 255, 255, 0.4);
$color_waterloo__approx: #7c8694;
$color_curious_blue_approx: #2ba3df;
$color_keppel_approx: #33b5aa;
$color_macaroni_and_cheese_approx: #fcbd79;
$color_fiord_approx: #455a70;

//fonts
$font-family-arial: 'Arial';
$font-family-font-awesome: $fa-style-family;
$font-family-roboto: 'Roboto';
$font-family-segoe-ui: 'Segoe UI';
$font-family-helvetica-neue: 'Helvetica Neue';
$font-family-helvetica: 'Helvetica';
$font-family-sans-serif: 'sans-serif';
$font-family-open-sans: 'Open Sans';

@font-face {
    font-family: 'Roboto';
}
$daterangepicker-color: #000;
$daterangepicker-bg-color: $color7;
$daterangepicker-cell-size: 20px;
$daterangepicker-padding: 10px;
$daterangepicker-cell-min-width: 0;
$daterangepicker-cell-padding: 0;
$daterangepicker-cell-border-size: 3px;
$daterangepicker-cell-border-radius: 0;
$daterangepicker-cell-border-color: $daterangepicker-bg-color;
$daterangepicker-unselected-border-color: $daterangepicker-bg-color;
$daterangepicker-cell-bg-color: white;
$daterangepicker-calendar-margin: 0;
$daterangepicker-header-cell-border-size: 3px;
$daterangepicker-header-cell-border-color: #7a7e8a;
$daterangepicker-header-cell-background-color: #7a7e8a;
$daterangepicker-control-color: #4c5264;
$daterangepicker-ranges-hover-bg-color: #4c5264;
$daterangepicker-active-bg-color: #0090d9;
$daterangepicker-in-range-bg-color: #0090d9;
$daterangepicker-cell-hover-bg-color: color.adjust(#0090d9, $lightness: -10%);
$daterangepicker-cell-hover-color: #fff;
$daterangepicker-active-color: #fff;
$daterangepicker-active-border-color: $color7;
$daterangepicker-cell-border-radius: 0;
$daterangepicker-control-active-border-size: 3px;
$daterangepicker-unselected-border-color: $color7;
$daterangepicker-in-range-border-color:  $color7;
$daterangepicker-in-range-color:  #fff;
$daterangepicker-cell-width: 22px;

$general-title-template-color: #505458;
