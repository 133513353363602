@use 'abstracts/mixins';
@import 'base/typography';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'vendor/animate';
@import 'vendor/pace';

// region layout
html {
    height: 100%;
}

body {
    direction: ltr;
    font-size: 13px;
    line-height: 1.5;
    font-style: normal;
    height: 100%;
    background: #30cbc9 url('../images/login/admin_bgr_image.jpg') no-repeat;
    color: #828997;
    font-family: Roboto, sans-serif;
    font-weight: 300;
}

a {
    text-shadow: none !important;
    color: #236bcc;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.1s linear 0s !important;
    font-size: 14px;

    &, &:focus, &:hover, &:active {
        outline: 0;
        text-decoration: none;
    }
}


.container {
    height: 100%;
}

.logo_container {
    position: fixed;
    top: 20px;
    left: 20px;
}

.box-pusher {
    min-height: 140px;
    height: 13%;
}

.login-container {
    .around-header {
        padding: 40px 70px 0 70px;

        > h1 {
            margin-top: 7px;
            color: #4b566a;
            margin-bottom: 37px;
            font-weight: 300;
            line-height: 35px;
        }

        > p {
            color: #828997;
            font-size: 14px;
            margin: 0 0 0 3px;
            padding-bottom: 23px;
            line-height: 22px;
        }
    }

    > div {
        border: 1px solid #beefee;
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;
    }

    .withflap {
        display: block;
        width: 100%;
        height: auto;
    }
}

.login-body {
    padding: 45px 65px 30px 65px;
    background: #eee !important;

    .form-group {
        margin-bottom: 10px;
    }

    .text-danger {
        font-weight: normal;
        font-size: 14px;
    }
}
// endregion

// region form
form {
    label {
        display: block;
        margin-bottom: 5px;
    }

    label,
    input,
    button {
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
    }

    input[type="text"],
    input[type="password"],
    input[type="email"] {
        color: #828997;
        height: 33px;
        font-size: 14px;
        line-height: 33px;
        font-weight: 300;
        padding: 0 11px;
        -webkit-appearance: none !important;
        outline: 0;
        vertical-align: top;
        background-color: #fff;
        box-shadow: none !important;
        border-radius: 2px;
        border: 1px solid #e5e9ec;
        transition: background 0.2s linear 0s, box-shadow 0.2s linear 0s;

        &:focus {
            border-color: #f1f1f1;
            background-color: #f4f5f7;
            box-shadow: none;
        }
    }

    input {
        border: 1px solid #cecece;

        &.error {
            border: 1px solid #f35958;
        }
    }

    .checkbox {
        label {
            display: inline-block;
            cursor: pointer;
            position: relative;
            padding-left: 25px;
            margin-right: 15px;
            font-size: 13px;
            color: #777a80;
            transition: border 0.2s linear 0s, color 0.2s linear 0s;
            margin-bottom: 10px;
            direction: ltr;

            &::before {
                content: "";
                display: inline-block;
                width: 17px;
                height: 17px;
                margin-right: 10px;
                position: absolute;
                left: 0;
                top: -.5px;
                background-color: #fff;
                border: 1px solid #c2c6cb;
                border-radius: 3px;
                transition: border 0.2s linear 0s, color 0.2s linear 0s;
            }

            &::after {
                display: inline-block;
                width: 16px;
                height: 16px;
                position: absolute;
                left: 3.2px;
                top: 0;
                font-size: 11px;
                transition: border 0.2s linear 0s, color 0.2s linear 0s;
                border-radius: 3px;
            }
        }

        input[type=checkbox] {
            display: none;

            &:checked + label {
                color: #4d5056;

                &::after {
                    @include mixins.icon($fa-var-check);
                }
            }

            &[disabled] + label {
                opacity: 0.65;
            }
        }

        &.check-success input[type=checkbox]:checked + label {
            &::before {
                background-color: #2baeac;
                border: 1px solid #2baeac;
            }
            &::after {
                color: #fff;
            }
        }

        label {
            padding-left: 28px;
            font-weight: lighter;
            font-size: 14px;
            margin-left: 4px;

            &::before {
                border: none;
            }
        }
    }

    div.checkbox {
        display: inline-block;
    }

    .loginbtn {
        float: right;
        font-size: 16px;
        font-weight: 300;
        margin-right: 0;
        background: #30cbc9;
        text-transform: capitalize;
        padding: 7px 9px;
        transition: all 0.3s ease;
        min-width: 120px;

        &:hover, &:focus {
            background: #2baeac;
        }
    }
}

.form-row {
    padding-top: 5px;
    padding-bottom: 5px;
}
// endregion

// region bootstrap overrides
.btn {
    line-height: 20px;
    background-image: none !important;
    border: none;
    text-shadow: none;
    box-shadow: none;
    transition: all 0.12s linear 0s !important;

    &:focus {
        outline: none;
    }
}
// endregion

.tooltip-inner {
    white-space: pre-wrap;
    max-width: 300px;
    padding: 6px 9px;
}

.tiles {
    &.white {
        background-color: #fff;
        color: #8b91a0;

        label {
            color: #9aa0ad;
        }
    }
}

@media (max-width: 767px) {
    .login-container .around-header {
        padding: 20px;
    }

    .login-container .around-header > p {
        padding-bottom: 10px;
    }

    .login-body {
        padding: 20px;
    }

    .checkbox {
        text-align: center;
    }

    .loginbtn {
        width: 100%;
        float: none;
    }
}
